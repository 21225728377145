export const BaseReducer = (state, action) => {
    switch (action.type) {
        case 'SET_BREADCRUMBS':
            return {
                ...state,
                breadcrumbs: action.payload
            };
        case 'UPDATE_DEVICE_DETAILS':
            return {
                ...state,
                updateDeviceDetails: action.payload
            };
        case 'SET_DEVICE_ALL':
                return {
                    ...state,
                    deviceAll: action.payload
                };
        default:
            return state
    }
}