// import './App.scss';
import Home from './Components/Home';
import ItemList from './Components/ItemList';
import DeviceDetails from './Components/DeviceDetails';
import About from './Components/About';
import Terms from './Components/Terms';
import PrivacyPolicy from './Components/PrivacyPolicy';
import Sitemap from './Components/Sitemap';
import NoMatch from './Components/status/NoMatch';
import BaseContextProvider from './contexts/BaseContext';
import 'react-loading-skeleton/dist/skeleton.css'

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";

function App() {
  return (
    <>
      <BaseContextProvider>
        <Router>
            <Routes>
                <Route exact path="/" element={<Home/>}></Route>
                <Route path="/d/c/:id/:catname/" element={<ItemList/>}></Route>
                <Route path="/d/l/:catname/" element={<ItemList/>}></Route>
                <Route path="/d/p/:catname/" element={<ItemList/>}></Route>
                <Route path="/d/:id/:devicename/" element={<DeviceDetails/>}></Route>
                <Route path="/about/" element={<About/>}></Route>
                <Route path="/terms/" element={<Terms/>}></Route>
                <Route path="/privacy-policy/" element={<PrivacyPolicy/>}></Route>
                <Route path="/sitemap/" element={<Sitemap/>}></Route>
                <Route path="*" element={<NoMatch/>}></Route>
            </Routes>
        </Router>
      </BaseContextProvider>
    </>
  );
}

export default App;
