import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Breadcrumb, Image, Table, ListGroup, Card } from 'react-bootstrap';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

function LoadingDetails() {
    return (
        <div className="d-flex m-4">
            <Container>
                <Row className="mb-4">
                    <Col xs={3}>
                        <Skeleton height={150} />
                    </Col>
                    <Col xs={5}>
                        <Skeleton height={10} count={6} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Skeleton height={10} count={5} />
                    </Col>
                </Row>
            </Container>
            
        </div>
    );
}
export default LoadingDetails;