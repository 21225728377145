import React from 'react';
import { Container, Row, Col, Breadcrumb, Image, Table, ListGroup } from 'react-bootstrap';

function NoMatch() {
  return (
    <>
    <div>
        <Container className="bg-white rounded-large p-5 mt-4">
            <Row>
                <Col>
                    <h1 className="font-size-h3">404</h1>
                    <p>The content you are looking for doesn't exist.</p>
                </Col>
            </Row>
        </Container>
    </div>
    </>
  );
}

export default NoMatch;