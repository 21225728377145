import React, { useState, useEffect, useContext } from 'react';
import Logo from '../img/site/main_logo_orange_white.svg'
import AppleLogo from '../img/site/apple.png'
import DevicePhoto from '../img/device/m30s.jpg'
import { Container, Row, Col, Form, Image, Card, Button } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import Footer from './Footer';
import axios from "axios";
import qs from 'qs';
import { BaseContext } from '../contexts/BaseContext';
import { convertEmptySpaceToHyphen } from '../common/GlobalFunction';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { LoadingDevices } from '../skeleton';
import MetaTags from 'react-meta-tags';

function Home() {
  const [deviceCat, setDeviceCat] = useState([])
  const [deviceLatest, setDeviceLatest] = useState([])
  const [devicePopular, setDevicePopular] = useState([])
  const [deviceCatSliceSize, setDeviceCatSliceSize] = useState(8)
  const [btnAllBrands, setBtnAllBrands] = useState(true)
  const [deviceAll, setDeviceAll] = useState([])

  const [DLIDLatest, setDLIDLatest] = useState('')
  const [DLIDLocal, setDLIDLocal] = useState('')

  const { state, dispatch } = useContext(BaseContext);

  const navigate = useNavigate();

  const items = [
    {
      device_name: "Symphony Z30 Pro",
      device_thumb: "https://api.phonerdam.com/api/media/device_thumb/BUh1xuhkjqH8uLDaRm3mNgSyBeKdrpldFr5XO2jA.png",
      id: 3,
      misc_price_bdt: "10890"
    },
    {
      device_name: "Symphony Z35 (4GB+64GB)",
      device_thumb: "https://api.phonerdam.com/api/media/device_thumb/gHPJKNyUZiNOFBOYAdYr8fJ23RMxyVl9zrwowrln.png",
      id: 2,
      misc_price_bdt: "10990"
    }
    
  ]
  
  useEffect(() => {
    // Retriving device categories
    axios.get('https://api.phonerdam.com/api/device/category/').then((response) => {
      // console.log(response.data);
      setDeviceCat(response.data);
    });

    // Retriving latest device
    axios.get('https://api.phonerdam.com/api/device/latest/').then((response) => {
      // console.log(response.data);
      setDeviceLatest(response.data);
    });

    // Retriving popular device
    axios.get('https://api.phonerdam.com/api/device/popular/').then((response) => {
      // console.log(response.data);
      setDevicePopular(response.data);
    });
  }, [])

  const getDLIDLatest = () => {
    axios.get('https://api.phonerdam.com/api/device/fl/li/').then((response) => {
      localStorage.setItem('DLID', JSON.stringify(response.data[0].id))
    })
  }

  const getDeviceAll = () => {
    axios.get('https://api.phonerdam.com/api/device/fl/').then((response) => {
      localStorage.setItem('deviceAll', JSON.stringify(response.data))
      dispatch({ type: "SET_DEVICE_ALL", payload: response.data })
      getDLIDLatest()
    })
  }

  useEffect(() => {
    var DLIDLocal = JSON.parse(localStorage.getItem('DLID'));
    var deviceAllLocal = JSON.parse(localStorage.getItem('deviceAll'));
    
    DLIDLocal === null ? (
      getDLIDLatest()
    ) : (
      // Retriving latest device id
      axios.get('https://api.phonerdam.com/api/device/fl/li/').then((response) => {
        response.data[0].id !== DLIDLocal && (
          getDeviceAll()
        )
      })
    )

    deviceAllLocal === null || deviceAllLocal === '' || deviceAllLocal === undefined ? (
      getDeviceAll()
    ) : (
      dispatch({ type: "SET_DEVICE_ALL", payload: deviceAllLocal })
    )
    
  }, [])

  // useEffect(() => {

  //   console.log('local: '+DLIDLocal, 'latest: '+DLIDLatest)
  //   var deviceAllLocal = JSON.parse(localStorage.getItem('deviceAll'));

  //   deviceAllLocal === null ? (
  //     axios.get('https://api.phonerdam.com/api/device/fl/').then((response) => {
  //       localStorage.setItem('deviceAll', JSON.stringify(response.data))
  //       dispatch({ type: "SET_DEVICE_ALL", payload: response.data })
  //       // console.log('API')
  //     })
  //   ) : (
  //     dispatch({ type: "SET_DEVICE_ALL", payload: deviceAllLocal })
  //   )
  // }, [DLIDLatest, DLIDLocal])

  const handleBtnAllBrands = (length) => {
    setDeviceCatSliceSize(length);
    setBtnAllBrands(!btnAllBrands);
  }

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    // console.log(string, results)
  }

  const handleOnHover = (result) => {
    // the item hovered
    // console.log(result)
  }

  const handleOnSelect = (item) => {
    // the item selected
    let device_name = convertEmptySpaceToHyphen(item.device_name)
    // console.log(`/d/${item.id}/${device_name}`)
    navigate(`/d/${item.id}/${device_name}`)
  }

  const handleOnFocus = () => {
    // console.log('Focused')
  }

  const formatResult = (item) => {
    return item
    // return (<p dangerouslySetInnerHTML={{__html: '<strong>'+item+'</strong>'}}></p>); //To format result as html
  }


  return (
    <>
    <MetaTags>
        <title>Phonerdam.com ~ mobile phone review, news, specifications</title>
        <meta name="description" content="Phonerdam.com is one of the popular mobile pricing and comparison platform in Bangladesh. Easily get an idea of the current mobile phone price in Bangladesh." />
        
        {/* Facebook Meta Tags */}
        <meta property="og:type" content="Website"/>
        <meta property="og:title" content="Phonerdam.com ~ mobile phone review, news, specifications" />
        <meta property="og:description" content="Phonerdam.com is one of the popular mobile pricing and comparison platform in Bangladesh. Easily get an idea of the current mobile phone price in Bangladesh."/>
        <meta property="og:image" content="https://phonerdam.com/fb_og_banner.png" />
    </MetaTags>
    <div className="bg-dark home-hero">
      <Container>
          <Row>
            <Image src={Logo} height={50} className="mb-4 site-logo" />
          </Row>
          {/* {console.log(state.deviceAll)} */}
          <Row>
              <Col md={{ span: 6, offset: 3 }}>
                <Form>
                  <Form.Group className="mb-3 innerIcon">
                    <div className='searchAutoComplete'>
                      <ReactSearchAutocomplete
                        items={state.deviceAll}
                        fuseOptions={{ keys: ["device_name", "misc_price_bdt"] }}
                        resultStringKeyName={"device_name"}
                        maxResults={100}
                        onSearch={handleOnSearch}
                        onHover={handleOnHover}
                        onSelect={handleOnSelect}
                        onFocus={handleOnFocus}
                        autoFocus
                        placeholder="Type model or brand name"
                        formatResult={formatResult}
                      />
                    </div>
                  </Form.Group>
                </Form>
              </Col>
          </Row>
        </Container>
    </div>

    <div className="pt-5 pb-5 bg-white">
      <Container className="">
        <Row>
          {deviceCat.length !== 0 ? (
            deviceCat.slice(0, deviceCatSliceSize).map(item => (
              <Col md={3} xs={6} className="mb-3" key={item.id}>
              <Link to={`/d/c/${item.id}/${item.cat_name}-device-price-in-bangladesh`} className="card-link">
                <Card className="style-1" border="white">
                  <div className="text-center d-com-logo justify-content-md-center">
                    <Card.Img variant="top" src={item.cat_thumb} />
                  </div>
                  <Card.Body className="text-center">
                    <Card.Title>{item.cat_name}</Card.Title>
                    <Card.Text>
                      {item.device_count} devices
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
            ))
          ) : (
            <LoadingDevices/>
          )}
        </Row>
        { btnAllBrands && deviceCat.length !== 0 &&
          <div className="text-center">
            <Button variant="dark" className="btn-base round" onClick={() => handleBtnAllBrands(deviceCat.length) }>All brands <i className="las la-angle-down"></i></Button>
          </div>
        }
      </Container>
    </div>

    <div className="pt-5 pb-5 bg-light heading-with-row">
      <Container>
        <h3>All latest mobile device</h3>
        <Row>
          {deviceLatest.length !== 0 ? (
            deviceLatest.map(item => 
              <Col md={3} xs={6} className="mb-3" key={item.id}>
                <Link to={`/d/${item.id}/${item.device_name.replace(/[ ]/g, '-')}-price-in-bangladesh-and-${item.device_name.replace(/[ ]/g, '-')}-specifications`} className="card-link">
                  <Card className="style-2">
                    <div className="text-center d-com-logo">
                      <Card.Img variant="top" src={item.device_thumb} />
                    </div>
                    <Card.Body className="text-center">
                      <Card.Title>{item.device_name}</Card.Title>
                      <Card.Text className="text-danger">
                        {item.misc_price_bdt}/- BDT
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Link>
              </Col>
          )) : (
            <LoadingDevices/>
          )}
        </Row>
        <div className="text-center">
          <Link to="d/l/all-latest-mobile-phone-in-bangladesh/">
            <Button variant="dark" className="btn-base round">View all</Button>
          </Link>
        </div>
        </Container>
    </div>

    <div className="pt-5 pb-5 bg-white heading-with-row">
      <Container>
        <h3>Popular mobile device</h3>
        <Row>
        {devicePopular.length !== 0 ? (
            devicePopular.map(item => 
              <Col md={3} xs={6} className="mb-3" key={item.id}>
                <Link to={`/d/${item.id}/${item.device_name.replace(/[ ]/g, '-')}-price-in-bangladesh-and-${item.device_name.replace(/[ ]/g, '-')}-specifications`} className="card-link">
                  <Card className="style-2">
                    <div className="text-center d-com-logo">
                      <Card.Img variant="top" src={item.device_thumb} />
                    </div>
                    <Card.Body className="text-center">
                      <Card.Title>{item.device_name}</Card.Title>
                      <Card.Text className="text-danger">
                        {item.misc_price_bdt}/- BDT
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Link>
              </Col>
          )) : (
            <LoadingDevices/>
          )}
        </Row>
        <div className="text-center">
          <Link to="d/p/all-popular-mobile-phone-in-bangladesh/">
            <Button variant="dark" className="btn-base round">View all</Button>
          </Link>
        </div>
        </Container>
    </div>
    <Footer/>
    </>
  );
}

export default Home;