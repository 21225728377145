import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Image, Form } from 'react-bootstrap';
import Logo from '../img/site/main_logo_orange_white.svg'
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { Link, useNavigate } from "react-router-dom";
import { convertEmptySpaceToHyphen } from '../common/GlobalFunction';
import { BaseContext } from '../contexts/BaseContext';
import axios from "axios";

function Header() {

    const { state, dispatch } = useContext(BaseContext);

    const navigate = useNavigate();
    
    const handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        // console.log(string, results)
    }
    
    const handleOnHover = (result) => {
        // the item hovered
        // console.log(result)
    }
    
    const handleOnSelect = (item) => {
        // the item selected
        let device_name = convertEmptySpaceToHyphen(item.device_name)
        // console.log(`/d/${item.id}/${device_name}`)
        navigate(`/d/${item.id}/${device_name}`)
        dispatch({ type: "UPDATE_DEVICE_DETAILS", payload: state.updateDeviceDetails + 1 })
    }
    
    const handleOnFocus = () => {
        // console.log('Focused')
    }
    
    const formatResult = (item) => {
        return item
        // return (<p dangerouslySetInnerHTML={{__html: '<strong>'+item+'</strong>'}}></p>); //To format result as html
    }

    const getDLIDLatest = () => {
        axios.get('https://api.phonerdam.com/api/device/fl/li/').then((response) => {
          localStorage.setItem('DLID', JSON.stringify(response.data[0].id))
        })
      }
    
      const getDeviceAll = () => {
        axios.get('https://api.phonerdam.com/api/device/fl/').then((response) => {
          localStorage.setItem('deviceAll', JSON.stringify(response.data))
          dispatch({ type: "SET_DEVICE_ALL", payload: response.data })
          getDLIDLatest()
        })
      }
    
      useEffect(() => {
        var DLIDLocal = JSON.parse(localStorage.getItem('DLID'));
        var deviceAllLocal = JSON.parse(localStorage.getItem('deviceAll'));
        
        DLIDLocal === null ? (
          getDLIDLatest()
        ) : (
          // Retriving latest device id
          axios.get('https://api.phonerdam.com/api/device/fl/li/').then((response) => {
            response.data[0].id !== DLIDLocal && (
              getDeviceAll()
            )
          })
        )
    
        deviceAllLocal === null || deviceAllLocal === '' || deviceAllLocal === undefined ? (
          getDeviceAll()
        ) : (
          dispatch({ type: "SET_DEVICE_ALL", payload: deviceAllLocal })
        )
        
      }, [])

      
  return (
    <>
    <header className="bg-dark pt-3 pb-3">
        <Container>
            <Row>
                <Col md={6} xs={4}>
                    <Link to="/">
                        <Image src={Logo} height={36} className="site-logo" />
                    </Link>
                </Col>
                <Col md={6} xs={8}>
                    <Form>
                    <div className='searchAutoComplete'>
                    <ReactSearchAutocomplete
                        items={state.deviceAll}
                        fuseOptions={{ keys: ["device_name", "misc_price_bdt"] }}
                        resultStringKeyName={"device_name"}
                        maxResults={100}
                        onSearch={handleOnSearch}
                        onHover={handleOnHover}
                        onSelect={handleOnSelect}
                        onFocus={handleOnFocus}
                        autoFocus
                        placeholder="Type model or brand name"
                        formatResult={formatResult}
                      />
                    </div>
                    </Form>
                </Col>
            </Row>
        </Container>
    </header>
    </>
  );
}

export default Header;