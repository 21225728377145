import React, { useState, useEffect, useContext } from 'react';
import DevicePhoto from '../img/device/m30s.jpg'
import { Container, Row, Col, Breadcrumb, Image, Table, ListGroup, Card } from 'react-bootstrap';
import { Link, useParams } from "react-router-dom";
import Footer from './Footer';
import Header from './Header';
import axios from "axios";
import { BaseContext } from '../contexts/BaseContext';
import { LoadingDetails } from '../skeleton';
import MetaTags from 'react-meta-tags';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

function DeviceDetails() {
    const { id, devicename } = useParams();
    const [deviceDetails, setDeviceDetails] = useState([])
    const [similarDevices, setSimilarDevices] = useState([])
    const [categoryname, setCategoryname] = useState([])
    const [loadingDetails, setLoadingDetails] = useState(true)

    const [updateDeviceDetails, setUpdateDeviceDetails] = useState(0)

    const { state, dispatch } = useContext(BaseContext);

    useEffect(() => {
        // Retriving device details
        axios.get('https://api.phonerdam.com/api/device/'+id+'/').then((response) => {
        //    console.log(response.data);
           setDeviceDetails(response.data);
           setLoadingDetails(false)
        })

        //  Getting category name
         let cn = devicename.replace(/[-]/g, ' ')
         let only_cn = cn.replace(/ .*/,'');
         setCategoryname(only_cn);
       }, [updateDeviceDetails, state.updateDeviceDetails])

       useEffect(() => {
        // Retriving similiar devices
        deviceDetails.length !== 0 &&
        axios.get('https://api.phonerdam.com/api/device/category/'+deviceDetails.device_category+'/').then((response) => {
            // console.log(response.data);
            setSimilarDevices(response.data);
        })
        // .then(
        //     similarDevices.length !== 0 &&
        //     console.log(similarDevices.map(item => item))
        // )
    }, [deviceDetails])

    const handleSimilarDevice = () => {
        setLoadingDetails(true)
        setUpdateDeviceDetails(updateDeviceDetails + 1)
        window.scrollTo(0, 0)
    }

  return (
    <>
    <div className="wrapper">
        <MetaTags>
        <title>{`${deviceDetails.device_name} price in bangladesh ${deviceDetails.misc_price_bdt} BDT | Phonerdam.com`}</title>
        <meta name="description" content= {`Released on: ${deviceDetails.hl_relesed}, OS: ${deviceDetails.hl_os}, Storage: ${deviceDetails.hl_storage}, Display: ${deviceDetails.hl_display_dm}, Camera: ${deviceDetails.hl_camera_main}, Battery: ${deviceDetails.hl_battery}`}/>
        
        {/* Facebook Meta Tags */}
        <meta property="og:type" content="Website"/>
        <meta property="og:title" content={`${deviceDetails.device_name} price in bangladesh ${deviceDetails.misc_price_bdt} BDT | Phonerdam.com`} />
        <meta property="og:description" content={`Released on: ${deviceDetails.hl_relesed}, OS: ${deviceDetails.hl_os}, Storage: ${deviceDetails.hl_storage}, Display: ${deviceDetails.hl_display_dm}, Camera: ${deviceDetails.hl_camera_main}, Battery: ${deviceDetails.hl_battery}`}/>
        <meta property="og:image" content={`${deviceDetails.device_thumb}`} />
        </MetaTags>
    </div>
    <Header/>
        {deviceDetails.length === 0 || deviceDetails === '' || loadingDetails === true ? (
            <LoadingDetails/>
        ) : (
        <>
            <Container className="mt-4">
                <Breadcrumb>
                    <Breadcrumb.Item href={`/d/c/${deviceDetails.device_category}/${categoryname}-device-price-in-bangladesh`}>
                        {categoryname}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>{devicename.replace(/[-]/g, ' ')}</Breadcrumb.Item>
                </Breadcrumb>
            </Container>
            <div>
                <Container className="bg-white rounded-large p-5">
                    {deviceDetails.length !== 0 && deviceDetails !== '' ? (
                        <>
                        <Row>
                            <Col md={12}>
                                <h1 class='deviceNameTitle'>{deviceDetails.device_name}</h1>
                            </Col>
                            <Col md={4}>
                                <Image className="img-fluid device-thumb-details" src={deviceDetails.device_thumb} />
                            </Col>
                            <Col md={8}>
                                <ListGroup className="hl-list">
                                    <ListGroup.Item><i className="las la-calendar"></i> Released: {deviceDetails.hl_relesed}</ListGroup.Item>
                                    <ListGroup.Item><i className="las la-code"></i> {deviceDetails.hl_os}</ListGroup.Item>
                                    <ListGroup.Item><i className="las la-sd-card"></i> {deviceDetails.hl_storage}</ListGroup.Item>
                                    <ListGroup.Item className="text-danger price-big-bold pb-0 mt-3">{deviceDetails.misc_price_bdt}/- BDT</ListGroup.Item>
                                    <ListGroup.Item className="price-small-bold">{deviceDetails.misc_price_all}</ListGroup.Item>
                                </ListGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <ListGroup horizontal className="hl-list-big mt-4">
                                    <ListGroup.Item>
                                        <i className="las la-mobile"></i>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <h3>{deviceDetails.hl_display_dm}</h3>
                                        <p>{deviceDetails.hl_display_res}</p>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Col>
                            <Col>
                                <ListGroup horizontal className="hl-list-big mt-4">
                                    <ListGroup.Item>
                                        <i className="las la-camera"></i>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <h3>{deviceDetails.hl_camera_main}</h3>
                                        <p>{deviceDetails.hl_camera_video}</p>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Col>
                            <Col>
                                <ListGroup horizontal className="hl-list-big mt-4">
                                    <ListGroup.Item>
                                        <i className="las la-battery-full"></i>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <h3>{deviceDetails.hl_battery}</h3>
                                        <p>{deviceDetails.hl_battery_type}</p>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Col>
                            <Col>
                                <ListGroup horizontal className="hl-list-big mt-4">
                                    <ListGroup.Item>
                                        <i className="las la-microchip"></i>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <h3>{deviceDetails.hl_storage}</h3>
                                        <p>{deviceDetails.hl_cpu}</p>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Col>
                        </Row>
                        <hr />
                        <Row className="dt-info">
                            <Col>

                            <h3 className="p-0">Launch</h3>
                                <Table>
                                    <tbody>
                                        <tr>
                                            <th>Launch</th>
                                            <td>{deviceDetails.launch_announced}</td>
                                        </tr>
                                        <tr>
                                            <th>Status</th>
                                            <td>{deviceDetails.launch_status}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <hr />

                                <h3 className="p-0">Display</h3>
                                <Table>
                                    <tbody>
                                        <tr>
                                            <th>Type</th>
                                            <td>{deviceDetails.display_type}</td>
                                        </tr>
                                        <tr>
                                            <th>Size</th>
                                            <td>{deviceDetails.display_size}</td>
                                        </tr>
                                        <tr>
                                            <th>Resolution</th>
                                            <td>{deviceDetails.display_resolution}</td>
                                        </tr>
                                        <tr>
                                            <th>Protection</th>
                                            <td>{deviceDetails.display_protection}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <hr />

                                <h3 className="p-0">Body</h3>
                                <Table>
                                    <tbody>
                                        <tr>
                                            <th>Dimensions</th>
                                            <td>{deviceDetails.body_dimensions}</td>
                                        </tr>
                                        <tr>
                                            <th>Weight</th>
                                            <td>{deviceDetails.body_weight}</td>
                                        </tr>
                                        <tr>
                                            <th>Build</th>
                                            <td>{deviceDetails.body_build}</td>
                                        </tr>
                                        <tr>
                                            <th>SIM</th>
                                            <td>{deviceDetails.body_sim}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <hr />


                                <h3 className="p-0">Platform</h3>
                                <Table>
                                    <tbody>
                                        <tr>
                                            <th>OS</th>
                                            <td>{deviceDetails.platform_os}</td>
                                        </tr>
                                        <tr>
                                            <th>CPU</th>
                                            <td>{deviceDetails.platform_cpu}</td>
                                        </tr>
                                        <tr>
                                            <th>GPU</th>
                                            <td>{deviceDetails.platform_gpu}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <hr />

                                <h3 className="p-0">Camera</h3>
                                <Table>
                                    <tbody>
                                        <tr>
                                            <th>Main Camera</th>
                                            <td>{deviceDetails.main_camera_modules}</td>
                                        </tr>
                                        <tr>
                                            <th>Main Camera Features</th>
                                            <td>{deviceDetails.main_camera_features}</td>
                                        </tr>
                                        <tr>
                                            <th>Main Camera Video</th>
                                            <td>{deviceDetails.main_camera_video}</td>
                                        </tr>
                                        <tr>
                                            <th>Selfie Camera</th>
                                            <td>{deviceDetails.selfie_camera_modules}</td>
                                        </tr>
                                        <tr>
                                            <th>Selfie Camera Features</th>
                                            <td>{deviceDetails.selfie_camera_features}</td>
                                        </tr>
                                        <tr>
                                            <th>Selfie Camera Video</th>
                                            <td>{deviceDetails.selfie_camera_video}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <hr />

                                <h3 className="p-0">Memory</h3>
                                <Table>
                                    <tbody>
                                        <tr>
                                            <th>RAM</th>
                                            <td>{deviceDetails.memory_ram}</td>
                                        </tr>
                                        <tr>
                                            <th>ROM</th>
                                            <td>{deviceDetails.memory_rom}</td>
                                        </tr>
                                        <tr>
                                            <th>External Memory</th>
                                            <td>{deviceDetails.memory_external}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <hr />

                                <h3 className="p-0">Connectivity</h3>
                                <Table>
                                    <tbody>
                                        <tr>
                                            <th>WLAN</th>
                                            <td>{deviceDetails.comms_wlan}</td>
                                        </tr>
                                        <tr>
                                            <th>Bluetooth</th>
                                            <td>{deviceDetails.comms_bluetooth}</td>
                                        </tr>
                                        <tr>
                                            <th>GPS</th>
                                            <td>{deviceDetails.comms_gps}</td>
                                        </tr>
                                        <tr>
                                            <th>NFC</th>
                                            <td>{deviceDetails.comms_nfc}</td>
                                        </tr>
                                        <tr>
                                            <th>Infrared</th>
                                            <td>{deviceDetails.comms_infrared_port}</td>
                                        </tr>
                                        <tr>
                                            <th>FM Radio</th>
                                            <td>{deviceDetails.comms_radio}</td>
                                        </tr>
                                        <tr>
                                            <th>USB</th>
                                            <td>{deviceDetails.comms_usb}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <hr />

                                <h3 className="p-0">Network</h3>
                                <Table>
                                    <tbody>
                                        <tr>
                                            <th>Technology</th>
                                            <td>GSM / HSPA / LTE</td>
                                        </tr>
                                        <tr>
                                            <th>2G bands</th>
                                            <td>{deviceDetails.two_g_brand}</td>
                                        </tr>
                                        <tr>
                                            <th>3G bands</th>
                                            <td>{deviceDetails.three_g_brand}</td>
                                        </tr>
                                        <tr>
                                            <th>4G bands</th>
                                            <td>{deviceDetails.four_g_brand}</td>
                                        </tr>
                                        <tr>
                                            <th>5G bands</th>
                                            <td>{deviceDetails.five_g_brand}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <hr />

                                <h3 className="p-0">Others</h3>
                                <Table>
                                    <tbody>
                                        <tr>
                                            <th>Sensors</th>
                                            <td>{deviceDetails.features_sensors}</td>
                                        </tr>
                                        <tr>
                                            <th>Finger Print</th>
                                            <td>{deviceDetails.finger_print}</td>
                                        </tr>
                                        <tr>
                                            <th>Face Unlock</th>
                                            <td>{deviceDetails.face_unlock}</td>
                                        </tr>
                                        <tr>
                                            <th>Notification Led</th>
                                            <td>{deviceDetails.notification_led}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <hr />

                                <h3 className="p-0">Battery</h3>
                                <Table>
                                    <tbody>
                                        <tr>
                                            <th>Battery</th>
                                            <td>{deviceDetails.hl_battery}</td>
                                        </tr>
                                        <tr>
                                            <th>Battery type</th>
                                            <td>{deviceDetails.battery_type}</td>
                                        </tr>
                                        <tr>
                                            <th>Battery charging</th>
                                            <td>{deviceDetails.battery_charging}</td>
                                        </tr>
                                        <tr>
                                            <th>Standby</th>
                                            <td>{deviceDetails.battery_standby}</td>
                                        </tr>
                                        <tr>
                                            <th>Talk time</th>
                                            <td>{deviceDetails.battery_talktime}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <hr />

                                <h3 className="p-0">Miscellaneous</h3>
                                <Table>
                                    <tbody>
                                        <tr>
                                            <th>Price in Bangladesh</th>
                                            <td>{deviceDetails.misc_price_bdt}/- BDT</td>
                                        </tr>
                                        <tr>
                                            <th>Price Int</th>
                                            <td>{deviceDetails.misc_price_all}</td>
                                        </tr>
                                        <tr>
                                            <th>Manufactured in</th>
                                            <td>{deviceDetails.manufactured_info}</td>
                                        </tr>
                                    </tbody>
                                </Table>

                            </Col>
                        </Row>
                        </>
                    ) : null}
                </Container>
            </div>
        
        {similarDevices.length !== 0 &&
            <Container className="similar-devices">
                <Row>
                    {similarDevices.map(item=> 
                    <Col md={3} xs={6} className="mb-3" key={item.id}>
                    <Link onClick={()=> handleSimilarDevice()} to={`/d/${item.id}/${item.device_name.replace(/[ ]/g, '-')}-price-in-bangladesh-and-${item.device_name.replace(/[ ]/g, '-')}-specifications`} className="card-link">
                        <Card className="style-2">
                        <div className="text-center d-com-logo">
                            <Card.Img variant="top" src={item.device_thumb} />
                        </div>
                        <Card.Body className="text-center">
                            <Card.Title>{item.device_name}</Card.Title>
                            <Card.Text className="text-danger">
                                {item.misc_price_bdt}/- BDT
                            </Card.Text>
                        </Card.Body>
                        </Card>
                    </Link>
                    </Col>
                    )}
                </Row>
            </Container>
        }
    </>
    )}
    <Footer/>
    </>
  );
}

export default DeviceDetails;