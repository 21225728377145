import React from 'react';
import { Container, Row, Col, Breadcrumb, Image, Table, ListGroup } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Footer from './Footer';
import Header from './Header';

function PrivacyPolicy() {
  return (
    <>
    <Header/>
    <div>
        <Container className="bg-white rounded-large p-5 mt-4">
            <Row>
                <Col>
                    <h1 className="font-size-h3">Privacy and Policy</h1>
                    <p>
                      Phonerdam is a service provided by Hervat Studio (subject to your compliance with the Privacy Policy set forth below).
                      Please read these Privacy Policy before using this platform for both Mobile App and Web Site.
                    </p>
                    <h5>General</h5>
                    <p>
                      At Phonerdam platform (Web and Mobile App), accessible at Phonerdam.com, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Phonerdam and how we use it.
                    </p>
                    <h5>Privacy</h5>
                    <p>
                      Phonerdam and Hervat Studio will collect information from Users. It is a condition of use of the Phonerdam that each User consents and authorises Phonerdam and Hervat Studio to collect and use this information. Phonerdam and Hervat Studio also reserves the right to disclose it to Company Affiliates and any other person for the purposes of administering, supporting and maintaining Phonerdam.com, as well as for improving Phonerdam.com, for example by using the information for research, marketing, product development and planning.
                    </p>
                    <h5>Collection</h5>
                    <p>
                      Information posted on Phonerdam is publicly available. If you choose to provide us with personal information, you are consenting to the transfer and storage of that information on our servers. We collect and store the following personal information:
                    </p>
                    <ul>
                      <li>Name, Email address, contact information, and (depending on the service used).</li>
                      <li>Computer sign-on data, statistics on page views, traffic to and from Phonerdam and response to advertisements.</li>
                      <li>Other information, including users' IP address and standard web log information.</li>
                      <li>Phonerdam use HTTPS protocol to collect any user data by it’s platform for both mobile app and website.</li>
                    </ul>
                    <h5>Use</h5>
                    <p>We use users' personal information to:</p>
                    <ul>
                      <li>Provide our services</li>
                      <li>Resolve disputes, collect fees, and troubleshoot problems</li>
                      <li>Encourage safe trading and enforce our policies</li>
                      <li>Customize users experience, measure interest in our services</li>
                      <li>Improve our services and inform users about services and updates</li>
                      <li>Communicate marketing and promotional offers to you according to your preferences</li>
                      <li>Do other things for users as described when we collect the information</li>
                    </ul>
                    <h5>Contact Us</h5>
                    <p>For any kind of information, help or privacy concern please email to Phonerdam platform at hello@phonerdam.com.</p>
                    <h5>Data Deletation</h5>
                    <p>Phonerdamstored any data by ensuring high security, user can delete his/ her personal data/account by emailing at hello@phonerdam.com.</p>
                </Col>
            </Row>
        </Container>
    </div>

    <Footer/>
    </>
  );
}

export default PrivacyPolicy;