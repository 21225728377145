import React, { createContext, useReducer } from 'react';
import { BaseReducer } from '../reducers/BaseReducer';
export const BaseContext = createContext();

const BaseContextProvider = (props) => {
    const initialState = {
        breadcrumbs: '',
        updateDeviceDetails: 0,
        deviceAll: []
    }

    const [state, dispatch] = useReducer(BaseReducer, initialState);

    return (
        <BaseContext.Provider value={{ state, dispatch }}>
            {props.children}
        </BaseContext.Provider>
    )
}

export default BaseContextProvider;