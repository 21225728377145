import React, { useState, useEffect } from 'react';
import DevicePhoto from '../img/device/m30s.jpg'
import { Container, Row, Col, Pagination, Image, Card, Button } from 'react-bootstrap';
import { Link, useParams } from "react-router-dom";
import Footer from './Footer';
import Header from './Header';
import { LoadingDevices } from '../skeleton';
import axios from "axios";
import MetaTags from 'react-meta-tags';

function Item() {
  const { id, catname } = useParams();
  const [deviceList, setDeviceList] = useState([])
  const [loadingDevices, setLoadingDevices] = useState(true)

  useEffect(() => {
  //  console.log(id);
    id !== '' && id !== undefined && (
      axios.get('https://api.phonerdam.com/api/device/category/'+id+'/').then((response) => {
          setDeviceList(response.data)
          setLoadingDevices(false)
        })
      )

    catname === 'all-latest-mobile-phone-in-bangladesh' && (
      axios.get('https://api.phonerdam.com/api/device/latest/all/').then((response) => {
        setDeviceList(response.data)
        setLoadingDevices(false)
      })
    )

    catname === 'all-popular-mobile-phone-in-bangladesh' && (
      axios.get('https://api.phonerdam.com/api/device/popular/all/').then((response) => {
        setDeviceList(response.data)
        setLoadingDevices(false)
      })
    )

  }, [])

  return (
    <>
    <Header/>
    
    <div className="cover-photo" style={{ backgroundImage: `url("https://phonerdam.com/img/sam_cat_bg.png")`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }}>
        <Container>
            <h1 className="text-center text-white">All {catname.replace(/[-]/g, ' ')}</h1>
        </Container>
    </div>
    

    <div className="main-container-floating">
      <Container className="bg-white rounded-large p-2 pb-4">
        {loadingDevices === true ? (
          <LoadingDevices/>
          ) : (
          
          <Row>
            {deviceList.length !== 0 && deviceList !== '' ? (
              deviceList.map(item => (
              <>
              <MetaTags>
                <title>{`${catname.replace(/[-]/g, ' ')} | Phonerdam.com`}</title>
                <meta name="description" content= ""/>
                
                {/* Facebook Meta Tags */}
                <meta property="og:type" content="Website"/>
                <meta property="og:title" content={`${catname.replace(/[-]/g, ' ')} | Phonerdam.com`}/>
                <meta property="og:description" content="Phonerdam.com is one of the popular mobile pricing and comparison platform in Bangladesh. Easily get an idea of the current mobile phone price in Bangladesh."/>
                <meta property="og:image" content="https://phonerdam.com/fb_og_banner.png" />
              </MetaTags>
              <Col md={3} xs={6} className="mb-3" key={item.id}>
                <Link to={`/d/${item.id}/${item.device_name.replace(/[ ]/g, '-')}-price-in-bangladesh-and-${item.device_name.replace(/[ ]/g, '-')}-specifications`} className="card-link">
                  <Card className="style-2">
                    <div className="text-center d-com-logo">
                      <Card.Img variant="top" src={item.device_thumb} />
                    </div>
                    <Card.Body className="text-center">
                      <Card.Title>{item.device_name}</Card.Title>
                      <Card.Text className="text-danger">
                        {item.misc_price_bdt}/- BDT
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Link>
              </Col>
              </>
              ))
            ) : (
              <div className="text-center not-found">
                <i class="las la-search-plus"></i>
                <p>No device found</p>
                <div className="text-center">
                  <Link to='/'>
                    <Button variant="dark" className="btn-base round">
                      Go to home
                    </Button>
                  </Link>
                </div>
              </div>
            )}
          </Row>
          )
        }
        {/* <Pagination className="mt-3">
            <Pagination.First />
            <Pagination.Prev />
            <Pagination.Item>{1}</Pagination.Item>
            <Pagination.Item active>{2}</Pagination.Item>
            <Pagination.Item>{3}</Pagination.Item>
            <Pagination.Item disabled>{4}</Pagination.Item>
            <Pagination.Ellipsis />
            <Pagination.Item>{20}</Pagination.Item>
            <Pagination.Next />
            <Pagination.Last />
        </Pagination> */}
        </Container>
    </div>

    <Footer/>
    </>
  );
}

export default Item;