import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Breadcrumb, Image, Table, ListGroup, Card } from 'react-bootstrap';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

function LoadingDevices() {
    return (
        <>
            <Row className="ps-5 pe-5 mt-5 mb-4">
                <Col sm={3}>
                    <Skeleton height={150} className="mb-3" />
                    <Skeleton height={10} />
                    <Skeleton height={10} />
                </Col>
                <Col sm={3}>
                    <Skeleton height={150} className="mb-3" />
                    <Skeleton height={10} />
                    <Skeleton height={10} />
                </Col>
                <Col sm={3}>
                    <Skeleton height={150} className="mb-3" />
                    <Skeleton height={10} />
                    <Skeleton height={10} />
                </Col>
                <Col sm={3}>
                    <Skeleton height={150} className="mb-3" />
                    <Skeleton height={10} />
                    <Skeleton height={10} />
                </Col>
            </Row>
            {/* <Row className="ps-5 pe-5">
                <Col sm={3}>
                    <Skeleton height={150} className="mb-3" />
                    <Skeleton height={10} />
                    <Skeleton height={10} />
                </Col>
                <Col sm={3}>
                    <Skeleton height={150} className="mb-3" />
                    <Skeleton height={10} />
                    <Skeleton height={10} />
                </Col>
                <Col sm={3}>
                    <Skeleton height={150} className="mb-3" />
                    <Skeleton height={10} />
                    <Skeleton height={10} />
                </Col>
                <Col sm={3}>
                    <Skeleton height={150} className="mb-3" />
                    <Skeleton height={10} />
                    <Skeleton height={10} />
                </Col>
            </Row> */}
        </>
    );
}
export default LoadingDevices;