import React, { useState, useEffect, useContext } from 'react';
import axios from "axios";
import { BaseContext } from '../contexts/BaseContext';
import { convertEmptySpaceToHyphen } from '../common/GlobalFunction';

function Sitemap() {
  const [deviceAllFl, setdeviceAllFl] = useState([])
  
  useEffect(() => {
    
  }, [])

  const handleBtnSiteMap = (length) => {
    // Retriving device list for generating sitemap.xml
    axios.get('https://api.phonerdam.com/api/device/fl/').then((response) => {
      console.log(response.data);
      setdeviceAllFl(response.data);
    });
  }



  return (
    <>
    <button onClick={()=> handleBtnSiteMap()}>Generate sitemap.xml</button>

    {/* <?xml version="1.0" encoding="UTF-8"?> */}




    {deviceAllFl.length !== 0 && 
        <>
        <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">

        <url>
        <loc>https://phonerdam.com/</loc>
        <lastmod>{new Date().getFullYear()}-{new Date().getMonth()+1}-{new Date().getDate()}</lastmod>
        <changefreq>monthly</changefreq>
        <priority>0.8</priority>
        </url>

        {deviceAllFl.map(item =>
            <>
                <url>
                    <loc key={item.id}>{`https://phonerdam.com/d/${item.id}/${convertEmptySpaceToHyphen(item.device_name)}/`}</loc>
                    <lastmod>{item.uptime.substring(0, 10)}</lastmod>
                </url>
            </>
        )}
        </urlset>
        </>
      }  
    </>
  );
}

export default Sitemap;