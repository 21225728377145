import React from 'react';
import { Container, Row, Col, Breadcrumb, Image, Table, ListGroup } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Footer from './Footer';
import Header from './Header';

function About() {
  return (
    <>
    <Header/>
    <div>
        <Container className="bg-white rounded-large p-5 mt-4">
            <Row>
                <Col>
                    <h1 className="font-size-h3">About Phonerdam.com</h1>
                    <p>
                      Phonerdam.com is an online portal where you can find almost all mobile devices specifications and prices in the Bangladesh market.
                    </p>
                </Col>
            </Row>
        </Container>
    </div>

    <Footer/>
    </>
  );
}

export default About;