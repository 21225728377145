import React from 'react';
import { Container, Row, Col, ListGroup } from 'react-bootstrap';
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
    <div className="pt-5 pb-5 bg-light">
        <Container>
            <footer>
                <Row>
                    <Col md={6}>
                        <h6 className="m-0">© {new Date().getFullYear()} Phonerdam.com</h6>
                    </Col>
                    <Col md={6}>
                        <ListGroup horizontal className="justify-content-end menu-list">
                            <Link to="/about" className="me-3">
                                <ListGroup.Item>About</ListGroup.Item>
                            </Link>
                            <Link to="/terms" className="me-3">
                                <ListGroup.Item>Terms and Conditions</ListGroup.Item>
                            </Link>
                            <Link to="/privacy-policy">
                                <ListGroup.Item>Privacy and Policy</ListGroup.Item>
                            </Link>
                        </ListGroup>
                    </Col>
                </Row>
            </footer>
        </Container>
    </div>
    </>
  );
}

export default Footer;